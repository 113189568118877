body {
  background-color: #1e1e1e;
  color: #d4d4d4;
  font-family: 'Consolas', monospace;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-size: 16px; /* Base font size */
}

.App {
  white-space: pre-wrap;
  padding: 1.25em; /* 20px / 16px */
  max-width: 50em; /* 800px / 16px */
  margin: 0 auto;
}

pre {
  background-color: #1e1e1e !important;
}

code {
  font-family: 'Consolas', monospace;
}

/* Add left margin and styling for content */
.markdown-content {
  margin-left: 3em; /* 48px / 16px */
  border-left: 1px solid #333;
  padding-left: 1.25em; /* 20px / 16px */
}

.markdown-content p {
  margin: 0.25em 0;  /* Reduced margin for more compact spacing */
}

.markdown-content code {
  display: inline-block;  /* Changed to inline-block */
  color: #666;
  font-size: 0.9em;
  margin: 0.1em 0;  /* Small margin to separate from surrounding text */
}
/* Existing styles */
.markdown-content img {
  max-width: 100%;
  height: auto;
  margin-top: 0em; /* Further reduced */
  margin-bottom: 0em;
}

.markdown-content audio {
  width: 100%;
  margin: 10px 0;
}

.markdown-content figcaption {
  font-style: italic;
  color: #666;
  /* margin-top: -2em;
  margin-bottom: -4em; */
  text-align: center
}

.markdown-content figure:has(audio) figcaption {
  margin-top: 0em; /* Even smaller for audio */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  body {
    font-size: 14px; /* Slightly smaller base font size for mobile */
  }

  .markdown-content {
    margin-left: 1.5em; /* Reduced margin for smaller screens */
    padding-left: 1em;
  }
}

.scroll-indicator {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 1000;
}